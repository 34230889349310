<template>
  <div id="educational">
    <div class="ll-c">
      <img src="@/assets/img/common/sy01.png"
           alt="" />
      <div class="educational-title">升级中......</div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";

// import router from "../../router/index.js";
export default {
  setup () {
    const state = reactive({});

    const methods = {};
    onMounted(() => { });
    onUnmounted(() => { });
    // provide('popfalse',state.popfalse)
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
#educational {
  height: 100%;
  .ll-c {
    text-align: center;
    width: 436px;
    height: 436px;

    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -10%);
    img {
      width: 100%;
      height: 100%;
      // position: absolute;
    }
    .educational-title {
      width: 104px;
      height: 21px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 21px;
      position: absolute;
      left: 50%;
      bottom: 25%;
      transform: translate(-50%, -25%);
    }
  }
  .cm-container {
    width: 1200px;
    margin: 0 auto;
    background: #f3f3f3;
    padding: 0 0 100px;
  }
}
@media screen and (min-width: 1600px) {
  #educational {
  }
}
</style>
